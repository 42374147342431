:root {
  --company-color: #64c191;
}

/* FONTS */
@font-face {
  font-family: FoubertHead;
  src: 
    local("BodinPostRegular"),
    url("/fonts/Sriracha-Regular.ttf") format("opentype");
}
@font-face {
  font-family: FoubertText;
  src: 
    local("raleway-medium"),
    url("/fonts/Sriracha-Regular.ttf") format("opentype");
}
body {
  font-family: 'FoubertText', 'Raleway', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*------------
    GENERAL
------------*/

.content {
  margin-top: 4rem;
}
.contentNoHeader {
  margin-top: 0rem;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

div.col-1, div.col-2, div.col-3, div.col-4, div.col-5, div.col-6, 
div.col-7, div.col-8, div.col-9, div.col-10,  div.col-11 {
  padding: 0;
}

p {
  margin-bottom: 0;
}

h2 {
  font-size: 2rem;
  /* text-decoration: underline #EF3340 dotted; */
  text-underline-position: under;
  font-family: 'FoubertHead', sans-serif;
  font-weight: 500;

  border-bottom: 3px solid var(--company-color);
  padding-bottom: 2px;
  display: inline-block;
}

/* ------------
    HEADER 
------------ */
  
.dark-overlay {
  background-color: rgba(40, 44, 52, 0.62);
  width: 100vw;
  height: 100vh;
  top: 0;
  position: fixed;
  z-index: 1001;
}

#logo {
  /* width: 15rem; */
  height: 2.5rem;
  /* margin-bottom: -13px; */
}

.navbar {
  height: 4rem;
  background-color: #FFF;
  color: #4a4a4a;
}

div.navbar-collapse > a.nav-item.nav-link {
  /* font-weight: bold; */
  color: #4a4a4a;
}
div.navbar-collapse > a.nav-item.nav-link:hover {
  text-decoration: underline;
}

a.nav-item.nav-link[aria-expanded="true"] {
  border: none;
}

div.navbar-collapse > a.nav-item.nav-link.webshop-blue, div.navbar-collapse > a.nav-item.nav-link span.webshop-blue {
  color: #62B5E5;
}
div.navbar-collapse > a.nav-item.nav-link.webshop-red, div.navbar-collapse > a.nav-item.nav-link span.webshop-red {
  color: var(--company-color);
}

@media only screen and (max-width: 799px) {
  div.navbar-collapse {
    background-color: white;
    width: 100vw;
    padding: 1rem;
    margin-top: 0;
    z-index: 1;
    position: absolute;
    top: 4rem;
    left: 0;
  }

  div.navbar-collapse > a.nav-item.nav-link {
    /* color: #EF3340; */
    color: #4a4a4a;
    font-weight: normal;
    /* text-align: center; */
    /* border-bottom: 2px solid red; */
    padding: 0.75rem;
  }

  div.navbar-collapse > a.nav-item.nav-link.bordered {
    border-top: 2px solid var(--company-color);
  }
}

@media only screen and (min-width: 800px) {
  .navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
  }

  div.navbar-collapse > a.nav-item.nav-link.no-big {
    display: none;
  }
}

/* ------------
    CARROUSEL 
------------ */
.full-width-carrousel {
  margin-left: calc(50% - 50vw);
  width: 100vw;
}

/* ------------
    MENU
------------ */

a.btn-filter {
  background-color: white;
  border: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

a.btn-filter:focus {
  box-shadow: none !important;
}

a.btn-filter img {
  width: 25px;
}

a[aria-expanded="true"]{
  border: 2px solid #EF3340;
}

.filter-panel{
  border: 2px solid #EF3340;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 1rem;
}

.gm-fullscreen-control {
  display: none!important;
 }

 /* ------------
    TRACKER
------------ */

.mapdiv {
  height: calc(100vh - 4rem);
  height: calc((var(--vh, 1vh) * 100) - 4rem);
  width: 100%;
}

.mapdivNoHeader {
  height: calc(100vh);
  height: calc((var(--vh, 1vh) * 100));
  width: 100%;
}

 .iconhand:hover {
   cursor: pointer;
 }

 .markertext {
  width: 2rem;
  margin-left: -1rem;
  margin-top: -1rem;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
 }

 div:hover>.markertext, .selectedmarkertext {
  width: 3rem;
  margin-left: -1.5rem;
  margin-top: -1.5rem;
 }

.markertext {
  position: absolute;
  line-height: 2rem;
  text-align: center;
  font-size: 0.8rem;
  color: white;
  font-family: 'FoubertHead', sans-serif;
  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
 }

 div:hover>span.markertext, span.selectedmarkertext {
  width: 3rem;
  height: 3rem;
  font-size: 1.2rem;
  line-height: 3rem;
 }

 .silentCallButton {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  margin: 0 auto;
  width: 3rem;
  z-index: 1000;
}

.trackerButton {
  position: fixed;
  left: 50%;
  bottom: 2rem;
  margin: 0 auto;
  width: 5rem;
  z-index: 1000;
  animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  -ms-animation: spin 4s linear infinite;
  -webkit-animation: spin 4s linear infinite;
  -o-animation: spin 4s linear infinite;
}

.trackerButtonText {
  position: fixed;
  left: 50%;
  bottom: 2rem;
  z-index: 1000;
  transform:translate(-50%, 0%);
  -moz-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
  -o-transform: translate(-50%, 0%);
  margin: 0 auto;
  width: 5rem;
}

@-moz-keyframes spin {
  0% {
    transform:translate(-50%, 0%) rotate(0deg);
    -moz-transform: translate(-50%, 0%) rotate(0deg);
    -ms-transform: translate(-50%, 0%) rotate(0deg);
    -webkit-transform: translate(-50%, 0%) rotate(0deg);
    -o-transform: translate(-50%, 0%) rotate(0deg);
  }
  100% { 
    transform: translate(-50%, 0%) rotate(0deg);
    -moz-transform: translate(-50%, 0%) rotate(0deg);
    -ms-transform: translate(-50%, 0%) rotate(0deg);
    -webkit-transform: translate(-50%, 0%) rotate(0deg);
    -o-transform: translate(-50%, 0%) rotate(0deg);
  }
}
@-webkit-keyframes spin {
  0% {
    transform:translate(-50%, 0%) rotate(0deg);
    -moz-transform: translate(-50%, 0%) rotate(0deg);
    -ms-transform: translate(-50%, 0%) rotate(0deg);
    -webkit-transform: translate(-50%, 0%) rotate(0deg);
    -o-transform: translate(-50%, 0%) rotate(0deg);
  }
  100% { 
    transform: translate(-50%, 0%) rotate(0deg);
    -moz-transform: translate(-50%, 0%) rotate(0deg);
    -ms-transform: translate(-50%, 0%) rotate(0deg);
    -webkit-transform: translate(-50%, 0%) rotate(0deg);
    -o-transform: translate(-50%, 0%) rotate(0deg);
  }
}
@keyframes spin {
  0% {
    transform:translate(-50%, 0%) rotate(0deg);
    -moz-transform: translate(-50%, 0%) rotate(0deg);
    -ms-transform: translate(-50%, 0%) rotate(0deg);
    -webkit-transform: translate(-50%, 0%) rotate(0deg);
    -o-transform: translate(-50%, 0%) rotate(0deg);
  }
  100% { 
    transform: translate(-50%, 0%) rotate(0deg);
    -moz-transform: translate(-50%, 0%) rotate(0deg);
    -ms-transform: translate(-50%, 0%) rotate(0deg);
    -webkit-transform: translate(-50%, 0%) rotate(0deg);
    -o-transform: translate(-50%, 0%) rotate(0deg);
  }
}

/*----------------------------------
      LOADER
----------------------------------*/
.loaderContainer {
  position: fixed;
  z-index: 1031;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
}

.loaderContainer .loader {
  background-color: rgba(255,255,255,0.75);
  border-radius: 5px;
  border: 2px solid #62b5e5;
  z-index: 1032;
  padding: 1rem;
  color: #62b5e5;
  max-width: 80%;
}

/*----------------------------------
      DIALOGBOX
----------------------------------*/
.dialogBoxContainer {
  position: fixed;
  z-index: 1031;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
}

.dialogBoxContainer .dialogBox {
  background-color: rgba(255,255,255,1);
  border-radius: 5px;
  border: 2px solid #62b5e5;
  z-index: 1032;
  padding: 1rem;
  /* color: #62b5e5; */
  max-width: 80%;
}
.dialogBoxContainer.error .dialogBox {
  border: 2px solid #EF3340;
  /* color: #EF3340; */
}

.dialogBoxContainer .dialogBox h2 {
  color: #62b5e5;
  border-color: #62b5e5;
}
.dialogBoxContainer.error .dialogBox h2 {
  color: #EF3340;
  border-color: #EF3340;
}

.dialogBoxContainer .dialogBox .buttons {
  margin-top: 1rem;
  justify-content: center;
}

.dialogBoxContainer .dialogBox .buttons .btn {
  color: #62b5e5;
  background: none;
  border-width: 2px;
  border-color: #62b5e5;
  margin: 0.5rem 0rem;
  width: 100%;
}

@media only screen and (min-width: 750px) {
  .dialogBoxContainer .dialogBox .buttons {
    display: flex;
  }

  .dialogBoxContainer .dialogBox .buttons .btn {
    margin: 0.5rem;
    width: auto;
  }
}

.dialogBoxContainer .dialogBox .buttons .btn:hover, .dialogBoxContainer .dialogBox .buttons .btn:active {
  color: white;
  background-color: #62b5e5;
  border-width: 2px;
  border-color: #62b5e5;
}

.dialogBoxContainer .dialogBox .buttons .btn.primary {
  color: white;
  background-color: #62b5e5;
  border-width: 2px;
  border-color: #62b5e5;
}
.dialogBoxContainer .dialogBox .buttons .btn.primary:hover, .dialogBoxContainer .dialogBox .buttons .btn.primary:active {
  color: #62b5e5;
  background: none;
  border-width: 2px;
  border-color: #62b5e5;
}

.dialogBoxContainer.error .dialogBox .buttons .btn {
  color: #EF3340;
  background: none;
  border-width: 2px;
  border-color: #EF3340;
}
.dialogBoxContainer.error .dialogBox .buttons .btn:hover, .dialogBoxContainer.error .dialogBox .buttons .btn:active {
  color: white;
  background-color: #EF3340;
  border-width: 2px;
  border-color: #EF3340;
}

.dialogBoxContainer.error .dialogBox .buttons .btn.primary {
  color: white;
  background-color: #EF3340;
  border-width: 2px;
  border-color: #EF3340;
}
.dialogBoxContainer.error .dialogBox .buttons .btn.primary:hover, .dialogBoxContainer.error .dialogBox .buttons .btn.primary:active {
  color: #EF3340;
  background: none;
  border-width: 2px;
  border-color: #EF3340;
}

/*----------------------------------
      TOAST
----------------------------------*/
.btoast {
  position: fixed;
  z-index: 1031;
  margin: auto;
  width: 80vw;
  left: 10vw;
  bottom: 50px;
  background-color: rgba(255,255,255,0.75);
  border-radius: 5px;
  border: 2px solid #62b5e5;
  padding: 1rem;
  color: #62b5e5;
  text-align: center;
}
.btoast.error {
  border-color: #ef3340;
  color: #ef3340;
}

/*----------------------------------
      CHOOSE LOCATION
----------------------------------*/
/* Customize the label (the container) */
label.customRadioBtn {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  padding: 1rem;
  border: 1px solid lightgrey;
  border-radius: 5px;
  min-height: 64px;
}

/* Hide the browser's default checkbox */
.customRadioBtn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.pin_image{
  max-height: 30px;
}

/* -----------------
    FORM VALIDATION
----------------- */

.form-control:focus{
  border-color: #62B5E5 !important;
  box-shadow: 0 0 0 0.2rem rgba(98, 181, 229, 0.25) !important;
}

.form-control.is-invalid:focus{
  border-color: #F8A9AF !important;
  box-shadow: 0 0 0 0.2rem rgba(239, 51, 64, 0.25) !important;
}